import "styles/pages/page-blog.scss";

import React from "react";

import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import Breadcrumps from "components/Breadcrumps";

import { Content } from "page_components/category";

const Category = ({ pageContext }) => {
  const pageData = pageContext.data;

  const breadcrumps_data = [
    {
      name: "Blog",
      href: "/blog/",
    },
    {
      name: pageData.name,
    },
  ];

  return (
    <Layout>
      <Seo title={pageData.name} />
      <Breadcrumps data={breadcrumps_data} />
      <SubpageHeader title={pageData.name} />
      <Content pageData={pageData} />
    </Layout>
  );
};

export default Category;
